import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';

import 'semantic-ui-css/semantic.min.css';
import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <Router history={history}>
        <WebSocketProvider>
        <AuthProvider>
          <Routes />
          <ToastContainer autoClose={3000} />
        </AuthProvider>
        </WebSocketProvider>
        <GlobalStyle />
      </Router>
    </>
  );
}

export default App;
