import React, { useContext } from 'react';
import { Menu, Button } from 'semantic-ui-react';

import {
  FaHome,
  // FaUsers,
  FaPhoneSquareAlt
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { MenuContainer } from './styles';
import { AuthContext } from '../../context/AuthContext'

export default function Header() {
  const history = useHistory()
  const currentLocation = window.location.pathname;
  const { logout } = useContext(AuthContext)

  return (
    <MenuContainer>
      <Menu stackable>
        <Menu.Item
          style={{color: '#019cd9'}}
          active={currentLocation === '/'}
          onClick={() => history.push('/')}
        >
          <FaPhoneSquareAlt size={24}/>
        </Menu.Item>

        <Menu.Item
          name="Dashboard"
          active={currentLocation === '/dashboard'}
          onClick={() => history.push('/dashboard')}
        >
          <FaHome size={12} />
          Dashboard
        </Menu.Item>

        {/* <Menu.Item
          name="Usuário"
          active={currentLocation.indexOf('/usuario') === 0}
          onClick={() => history.push('/usuario')}
        >
          <FaUsers />
          Usuários
        </Menu.Item> */}

        {/* <Menu.Item
          name="Chamados"
          active={currentLocation.indexOf('/chamado') === 0}
          onClick={() => history.push('/chamados')}
        >
          <FaBook />
          Chamados
        </Menu.Item>

        <Menu.Item
          name="Contatos"
          active={currentLocation.indexOf('/contato') === 0}
          onClick={() => history.push('/contatos')}
        >
          <FaThList />
          Contatos
        </Menu.Item> */}

        <Menu.Menu position="right">
          <Menu.Item>
            <Button color="red" onClick={() => logout()}>
              Logout
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </MenuContainer>
  );
}