import React, { createContext, useCallback, useState, useEffect } from 'react'
import socketio from 'socket.io-client';

export const WebSocketContext = createContext({
  user: null,
  domain: null,
  start: null,
  state: {},
  stopWebSocket: null,
  setWebSocketListener: null,
  setStart: null,
  socket: null
})

export const WebSocketProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [domain, setDomain] = useState(null);
  const [start, setStart] = useState(true);
  const [socket, setSocket] = useState(null);
  const [state, setState] = useState({});

  const setWebSocketListener = useCallback(({user, domain}) => {
    if(socket){
      return socket
    }

    setUser(user)
    setDomain(domain)
  }, [socket])

  const stopWebSocket = useCallback(() => {
    if(socket){
      socket.disconnect();
      setSocket(null)
      // setStart(null)
    }
  }, [socket])

  useEffect(() => {
    if(start === null && user !== null && domain !== null){
      console.log('aqui', user, domain)

      const io = socketio('https://api.cloudcom.com.br', {
        query: {
          domain: domain
        }
      })

      console.log('Fazendo bind do io')

      io.on('state', data => {
        setState({...state, ...data})
      })

      io.on('update', data => {
        setState({...state, ...data})
      })

      setSocket(io)
      setStart(true)
    }
  }, [start, user, domain, state])

  return (
    <WebSocketContext.Provider value={{ user, domain, start, state, socket, setStart, stopWebSocket, setWebSocketListener }}>
      {children}
    </WebSocketContext.Provider>
  )
}