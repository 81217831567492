import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react'

import { WebSocketContext } from '../../context/WebSocketContext'
import { AuthContext } from '../../context/AuthContext'

import {
  LegendaRegister,
  LegendaRinging,
  LegendaInbound,
  LegendaOutbound,
  UserRegister,
  UserCallInbound,
  UserCallOutbound,
  UserRinging,
  Painel,
  PainelLegendas
} from './styles';

export default function Dashboard() {
  const [showOnline, SetShowOnline] = useState(true)
  const [lista, setLista] = useState([])
  const { state, setWebSocketListener, stopWebSocket, setStart, start, socket } = useContext(WebSocketContext)
  const { user, domain } = useContext(AuthContext)

  useEffect(() => {
    const keys = Object.keys(state)
    setLista(keys.map(item => {
      if(!state[item].calls){
        state[item].TIPO = null
        return state[item]
      }
      const key = Object.keys(state[item].calls)
      if(key.length === 0){
        state[item].TIPO = null
        return state[item]
      }

      if(state[item].calls[key[0]].estado === 'Conexão' || state[item].calls[key[0]].estado === 'Chamando' || state[item].calls[key[0]].estado === 'Tentando'){
        state[item].TIPO = 'Chamando'
        return state[item]
      }

      state[item].TIPO = state[item].calls[key[0]].tipo
      return state[item]
    }))
  }, [state])

  useEffect(() => {
    console.log({start, socket})
    if(socket === null){
      setStart(null)
    }

    return () => {
      stopWebSocket()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopWebSocket])

  useEffect(() => {
    if(user !== null && domain !== null){
      console.log('setWebSocketListener', user, domain)
      setWebSocketListener({
        user,
        domain
      })
    }
  }, [domain, setWebSocketListener, user])

  return (
    <div>
      <div style={{ marginLeft: '5px' }}>
        {!showOnline && (
          <Button size='mini' onClick={() => {SetShowOnline(!showOnline)}} color='green'>Registrados</Button>
        )}
        {showOnline && (
          <Button size='mini' onClick={() => {SetShowOnline(!showOnline)}}>Registrados</Button>
        )}
        
      </div>
      <div style={{ marginLeft: '5px' }}>
        <p>Legendas</p>
      </div>
      
      <PainelLegendas>
        <LegendaRegister>
          <h3>REGISTRADO</h3>
        </LegendaRegister>
        <LegendaRinging>
          <h3>CHAMANDO</h3>
        </LegendaRinging>
        <LegendaInbound>
          <h3>RECEBIDA</h3>
        </LegendaInbound>
        <LegendaOutbound>
          <h3>DISCADA</h3>
        </LegendaOutbound>
      </PainelLegendas>
      <Painel>
        {lista.map(item => {
          if(item.STATUS === 'ONLINE' && item.TIPO === null){
            if(showOnline){
              return <></>
            }
            return (
              <UserRegister key={`${item.USUARIO}`}>
                <h3>{item.USUARIO}</h3>
                <h4>{item.RAMAL}</h4>
              </UserRegister>
            )
          }
          if(item.STATUS === 'ONLINE' && item.TIPO === 'Discada'){
            return (
              <UserCallOutbound key={`${item.USUARIO}`}>
                <h3>{item.USUARIO}</h3>
                <h4>{item.RAMAL}</h4>
              </UserCallOutbound>
            )
          }
          if(item.STATUS === 'ONLINE' && item.TIPO === 'Recebida'){
            return (
              <UserCallInbound key={`${item.USUARIO}`}>
                <h3>{item.USUARIO}</h3>
                <h4>{item.RAMAL}</h4>
              </UserCallInbound>
            )
          }
          if(item.STATUS === 'ONLINE' && item.TIPO === 'Chamando'){
            return (
              <UserRinging key={`${item.USUARIO}`}>
                <h3>{item.USUARIO}</h3>
                <h4>{item.RAMAL}</h4>
              </UserRinging>
            )
          }

          return <></>
          // return (
          //   <UserOffline key={`${item.USUARIO}`}>
          //     <h3>{item.USUARIO}</h3>
          //     <h4>{item.RAMAL}</h4>
          //   </UserOffline>
          // )
          // return(
          //   <div>
          //     {JSON.stringify(item)}
          //   </div>
          // )
        })}
      </Painel>
    </div>
  );
}