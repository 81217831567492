import React, { createContext, useCallback, useState } from 'react'
import { toast } from 'react-toastify';

import api from '../services/api'

export const AuthContext = createContext({
  user: null,
  domain: null,
  pass: null,
  signIn: null,
  logout: null
})

export const AuthProvider = ({children}) => {
  const [usuario, setUsuario] = useState(() => {
    const user = localStorage.getItem('@CloudTelefonista:user')

    if(user){
      return user
    }

    return null
  })
  const [dominio, setDominio] = useState(() => {
    const dominio = localStorage.getItem('@CloudTelefonista:dominio')

    if(dominio){
      return dominio
    }

    return null
  })
  const [pass, setPass] = useState(() => {
    const pass = localStorage.getItem('@CloudTelefonista:pass')

    if(pass){
      return pass
    }

    return null
  })

  const signIn = useCallback(async ({user, domain, password}) => {
    try {
      const { data } = await api.post('/basix/auth', {
        user,
        domain,
        password
      })

      localStorage.setItem('@CloudTelefonista:user', data.USUARIO)
      localStorage.setItem('@CloudTelefonista:dominio', data.DOMINIO)
      localStorage.setItem('@CloudTelefonista:pass', password)

      await api.post('/basix/realtime', {
        domain
      })

      setUsuario(data.USUARIO)
      setDominio(data.DOMINIO)
      setPass(password)
    } catch (error) {
      if(error.response){
        toast.error(error.response.data.error)
      }else{
        toast.error(error.message)
      }
    }
  }, [])

  const logout = useCallback(() => {
    localStorage.clear();
    document.location.reload(true);
  }, [])

  return (
    <AuthContext.Provider value={{ user: usuario, domain: dominio, pass, signIn, logout }}>
      {children}
    </AuthContext.Provider>
  )
}