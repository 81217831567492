import React, { useState, useContext } from 'react';
import { Button, Form, Image, Header, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { LoginContainer } from './styles';
import { AuthContext } from '../../context/AuthContext'

export default function SignIn() {
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');

  const { signIn } = useContext(AuthContext)

  const handleSubmit = () => {
    const [user, domain] = usuario.split('@')

    if(!user || !domain || !senha){
      return toast.error('Usuário ou senha preenchido incorretamente')
    }

    signIn({
      user,
      domain,
      password: senha
    })
  }

  return (
    <LoginContainer>
      <Message>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Image
              src="https://static.wixstatic.com/media/ab9c7e_9ba9bee2c0db419dbdc13aa125975a7e~mv2.png/v1/fill/w_222,h_46,al_c,q_80,usm_0.66_1.00_0.01/ab9c7e_9ba9bee2c0db419dbdc13aa125975a7e~mv2.webp"
              size="medium"
              centered
            />
            <Header as="h1">Telefonista</Header>
            <Form.Input
              label="Usuário"
              id="usuario"
              fluid
              placeholder="E-mail"
              value={usuario}
              onChange={e => setUsuario(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Senha"
              type="password"
              id="senha"
              name="senha"
              placeholder="Senha"
              value={senha}
              onChange={e => setSenha(e.target.value)}
            />
          </Form.Field>
          <Button primary type="submit">
            Entrar
          </Button>
        </Form>
      </Message>
    </LoginContainer>
  );
}