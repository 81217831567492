import styled from 'styled-components';

export const Painel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PainelLegendas = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Title = styled.h1`
  font-size: 48px;
  color: #3a3a3a;
`;

export const LegendaRegister = styled.div`
background-color: #8ed081;
width: 135px;
height: 40px;
padding: 10px;
border: 1px solid black;
margin: 5px;
`;

export const LegendaRinging = styled.div`
background-color: yellow;
width: 130px;
height: 40px;
padding: 10px;
border: 1px solid black;
margin: 5px;
`;

export const LegendaInbound = styled.div`
  background-color: #1e90ff;
  width: 110px;
  height: 40px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const LegendaOutbound = styled.div`
  background-color: lightblue;
  width: 100px;
  height: 40px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const UserRegister = styled.div`
  h3 {
    display: block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
  h4 {
    display: block;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;

  background-color: #8ed081;
  -width: 240px;
  -height: 80px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const UserRinging = styled.div`
  h3 {
    display: block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
  h4 {
    display: block;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;

  background-color: yellow;
  -width: 240px;
  -height: 80px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const UserOffline = styled.div`
  h3 {
    display: block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
  h4 {
    display: block;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;

  background-color: #8B0000;
  -width: 240px;
  -height: 80px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const UserCallInbound = styled.div`
  h3 {
    display: block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
  h4 {
    display: block;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;

  background-color: #1e90ff;
  -width: 240px;
  -height: 80px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;

export const UserCallOutbound = styled.div`
  h3 {
    display: block;
    margin: 0;
    padding: 0;
    margin-right: 10px;
  }
  h4 {
    display: block;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: row;

  background-color: lightblue;
  -width: 240px;
  -height: 80px;
  padding: 10px;
  border: 1px solid black;
  margin: 5px;
`;